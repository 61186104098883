import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { getEnCollectionTitle } from "../../../utils/helpers"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

//Styled components imports
import Link from "./Link"
import Wrapper from "./Wrapper"
import Title from "./Title"

const CollectionCard = ({ title, image, handle }) => {
  const intl = useIntl()

  const localeTitle = intl.locale === "en" ? getEnCollectionTitle(title) : title
  const bgImage = convertToBgImage(getImage(image && image.gatsbyImageData))

  return (
    <Link to={`/${handle}`}>
      <Wrapper fadeIn {...bgImage}>
        <Title>{localeTitle.replace(/.*\//, "")}</Title>
      </Wrapper>
    </Link>
  )
}

export default CollectionCard
