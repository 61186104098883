import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Wrapper = styled(BackgroundImage)`
  min-width: 320px;
  min-height: 290px;
  width: 100%;
  height: 100%;
  background-size: cover;
  text-align: center;
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    transform: scale(1.03);
    filter: brightness(93%);
  }
`

export default Wrapper
