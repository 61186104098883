import React from "react"

//React components imports
import CollectionCard from "./CollectionCard"

//Styled components imports
import Wrapper from "./Wrapper"

const Collections = ({ data }) => {
  return (
    <Wrapper>
      {data.reverse().map(collection => {
        const handle = collection.title.includes("/")
          ? collection.handle.replace("-", "/")
          : collection.handle
        return (
          <CollectionCard
            key={collection.id}
            title={collection.title}
            image={collection.image}
            handle={handle}
          />
        )
      })}
    </Wrapper>
  )
}

export default Collections
