import styled from "styled-components"

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 36px;
  text-shadow: 0px 3px 4px #0b0c0cdb, 0px 5px 10px #0b0c0cdb;
  font-weight: 700;
`

export default Title
