import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  max-width: 1200px;
  margin: auto;
  justify-items: center;
  grid-gap: 35px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1510px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default Wrapper
