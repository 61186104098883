import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Gatsby imports
import { graphql } from "gatsby"

//React components imports
import Layout from "../components/Layout"
import Collections from "../components/Collections"
import Breadcrumbs from "../components/Breadcrumbs"

//Styled components imports
import Title from "../shared-styled-components/Title"

const NewsPage = ({ data, location }) => {
  const intl = useIntl()
  const collections = data.collections.nodes
  return (
    <Layout subtitle={intl.formatMessage({ id: "pages.products.title" })}>
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: intl.formatMessage({ id: "pages.products.title" }),
        }}
      />
      <Title>{intl.formatMessage({ id: "pages.products.title" })}</Title>
      <Collections data={collections} />
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query MainCollections {
    collections: allShopifyCollection(
      filter: { title: { regex: "/^(?!.*/).*$/" } }
    ) {
      nodes {
        handle
        title
        id
        image {
          gatsbyImageData(width: 550)
        }
      }
    }
  }
`
